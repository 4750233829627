.container {
  @apply px-4;
}
@screen sm {
  .container {
    padding: 0 3.75rem 0 3.75rem;
  }
}
.title {
  font-weight: 600;
  text-align: center;
  font-family: Quattrocento;
  letter-spacing: 1px;
  color: theme("colors.black");
}
.column {
  @apply my-3;
}
.item {
  @apply flex flex-col mb-5 sm:px-5 sm:mb-0;
}
.icon {
  @apply flex items-end w-full pb-1 mr-4;
}
.itemTitle {
  font-family: Quattrocento;
  font-weight: 700 !important;

  margin-bottom: 8px !important;
  @apply ml-3 !important;
}
.itemTitle p {
  font-size: 18px;
}
